import { useEffect } from "react";
import {Col, Row} from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

function Success(props) {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get("from_cart") === "true") {
            window.localStorage.setItem("cart", JSON.stringify({}));
        }
    }, [])
    

    return (
        <>
            <Row className="justify-content-center text-center">
                <Col>
                    <div className="success-card">
                        <div style={{borderRadius:"200px", height:"200px", width:"200px", background: "#F8FAF5", margin:"0 auto"}}>
                            <i style={{fontWeight: 1000}} class="checkmark" id="success-checkmark">&#10003;</i>
                        </div>
                        <h1 id="success-title">Success</h1> 
                        <p id="success-text">Your order has been placed.</p>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Success;
