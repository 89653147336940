import { useEffect, useState } from "react";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import { useOutletContext, useParams } from "react-router-dom";
import ButtonSpinner from "../components/ButtonSpinner";

function Product() {
  let { productId } = useParams();

  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { storeName, setStoreName } = useOutletContext();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL || ""}/api/store/products/${productId}`)
      .then(raw => raw.json())
      .then(json => {
        if (json.success == false) {
          window.location.replace("/");
        }

        setProduct(json.result);
        setLoading(false);

        document.title = `${json.result.name} - ${storeName}`;
        document.description = ""
      })
  }, [productId]);

  function checkout() {
    setButtonLoading(true);

    let cart = {};
    cart[product.id] = {
      "count": 1,
      "name": product.name,
      "price": product.price,
      "currency": product.currency
    }

    fetch(`${process.env.REACT_APP_API_URL || ""}/api/store/get_payment_link`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        cart: cart,
        from_cart: false
      })
    })
      .then(raw => raw.json())
      .then(json => {
        window.open(json.result.link,"_self")
      });
  }

  function addToCart() {
    let cart = window.localStorage.getItem("cart");

    if (cart == undefined) {
      cart = {};
    } else {
      cart = JSON.parse(cart);
    }

    if (product.id in cart) {
      cart[product.id]["count"] += 1;
      cart[product.id]["name"] = product.name;
      cart[product.id]["price"] = product.price;
      cart[product.id]["currency"] = product.currency;
    } else {
      cart[product.id] = {
        "count": 1,
        "name": product.name,
        "price": product.price,
        "currency": product.currency
      };
    }

    window.localStorage.setItem("cart", JSON.stringify(cart));
  }

  return (
    <>
      <Row className="justify-content-center">
        {
          productId === undefined || loading
          ?
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          :
          <>
            {
              product.image != ""
              &&
              <Col md="12" lg="8" style={{overflow: "hidden"}}>
                <img 
                  className="mb-4 mb-lg-0"
                  id="product-image"
                  src={product.image}
                  alt={product.name}
                  style={{width: "100%"}}
                />
              </Col>
            }
            <Col className="justify-content-center align-self-center" md="12" lg="4">
                <h1>{product.name}</h1>
                <h5>{product.price} {product.currency}</h5>
                <p className="mt-4">{product.description}</p>
                <Button style={{"marginRight": "5px"}} onClick={checkout}>{buttonLoading ? <ButtonSpinner /> : "Buy Now"}</Button>
                <Button onClick={addToCart}>Add to Cart</Button>
            </Col>
          </>
        }
      </Row>
    </>
  );
}

export default Product;
