import { useEffect, useState } from "react";
import {Button, Card, Col, Row, Spinner} from "react-bootstrap";
import { useOutletContext } from "react-router-dom";

function Index(props) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [moreLoading, setMoreLoading] = useState(false);
  const { storeName, setStoreName } = useOutletContext();
  const [startingAfter, setStartingAfter] = useState("");
  const [hasMore, setHasMore] = useState(false);
  
  document.title = storeName;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL || ""}/api/store/products`)
      .then(raw => raw.json())
      .then(json => {
        setProducts(json.result);
        setStartingAfter(json.starting_after);
        setHasMore(json.has_more);
        setLoading(false);
      })
  }, [])

  const nextPage = () => {
    fetch(`${process.env.REACT_APP_API_URL || ""}/api/store/products?starting_after=${startingAfter}`)
      .then(raw => raw.json())
      .then(json => {
        let tempProducts = products.slice(0);
        tempProducts = tempProducts.concat(json.result);
        setProducts(tempProducts);
        
        setStartingAfter(json.starting_after);
        setHasMore(json.has_more);
        setLoading(false);
      })
  }

  return (
      <>
        <Row className="justify-content-center mb-4">
          {
            loading ?
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            :
              products.length === 0 ?
              <div className="text-center mt-5">
                <h2>This store has no products!</h2>
              </div>
              :
              products.map((product, i) => {
                return <Col className="d-flex justify-content-center mb-3" md="4" lg="3">
                  <Card style={{ width: '100%' }}>
                    <div className="rect-img-container">
                      {
                        product.image != "" 
                        &&
                        <Card.Img className="rect-img" variant="top" src={product.image} />
                      }
                    </div>
                    <Card.Body>
                      <Card.Title><a href={`/product/${product.id}`} class="stretched-link text-dark" style={{"textDecoration": "none"}}>{product.name}</a></Card.Title>
                      <Card.Text>
                        {product.price}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              })
          }
          {
            moreLoading 
            ?
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            :
              !loading && hasMore &&
                <Col className="d-flex justify-content-center" md="4" lg="3">
                  <Button onClick={nextPage}>Load More</Button>
                </Col>
          }
      </Row>
    </>
  );
}

export default Index;
