import { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, NavDropdown, Spinner, CloseButton, Row, Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import ButtonSpinner from "../components/ButtonSpinner";

const Layout = () => {
  const [storeName, setStoreName] = useState("");
  const [plan, setPlan] = useState("");
  const [trialExpiry , setTrialExpiry] = useState(9999999999999999999999);
  const [loading, setLoading] = useState(true);
  const [rerenderCart, setRerenderCart] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [active, setActive] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL || ""}/api/store/details`)
      .then(raw => raw.json())
      .then(json => {
        setStoreName(json.result.store_name);
        setPlan(json.result.plan);
        setTrialExpiry(json.result.trial_expiry);
        setActive(json.result.active);
        setLoading(false);
      })
  }, []);

  function checkout() {
    setButtonLoading(true);

    let cart = window.localStorage.getItem("cart");

    if (cart == null) {
      cart = "{}"
    }

    if (cart === "{}") {
      setButtonLoading(false);
    }

    fetch(`${process.env.REACT_APP_API_URL || ""}/api/store/get_payment_link`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        cart: JSON.parse(cart),
        from_cart: true
      })
    })
      .then(raw => raw.json())
      .then(json => {
        window.open(json.result.link,"_self")
      });
  }

  function removeFromCart(productId) {
    let cart = window.localStorage.getItem("cart");

    if (cart == undefined) {
      return;
    } else {
      cart = JSON.parse(cart);
    }

    if (productId in cart) {
      delete cart[productId];
      window.localStorage.setItem("cart", JSON.stringify(cart)); 
      setRerenderCart(!rerenderCart);
    }
  }

  function showCart() {
    let cartRaw = window.localStorage.getItem("cart");

    if (cartRaw == null) {
      cartRaw = "{}"
    }

    let cart = JSON.parse(cartRaw);
    let forceRerender = rerenderCart;

    if (cartRaw === "{}") {
      return <p>Your cart is empty!</p>
    }

    let newTotal = 0;
    let currency = "";

    for (let productId of Object.keys(cart)) {
      newTotal += cart[productId].price * cart[productId].count;
      currency = cart[productId].currency;
    }

    return (
      <>
        {Object.keys(cart).map((product, i) => (
          <Row>
            <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
              <p>{cart[product].count} x {cart[product].name} - {(cart[product].price * cart[product].count).toLocaleString()} {cart[product].currency}</p>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
              <CloseButton onClick={() => removeFromCart(product)} />
            </Col>
          </Row>
        ))}
        <hr></hr>
        <p><strong>Total:</strong> {newTotal.toLocaleString()} {currency}</p>
        {
          Object.keys(cart).length > 0 &&
          <Button className="w-100" onClick={checkout}>{buttonLoading ? <ButtonSpinner /> : "Checkout"}</Button>
        }
      </>
    )
  }

  return (
    loading
    ?
    <Container className="justify-content-center align-self-center text-center mt-5">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
    :
    <>
      {
        !active ?
        <>
          <Container className="content mb-3">
            <Row className="justify-content-center text-center">
              <Col>
                  <div className="success-card">
                      <h1 id="expired-title">Trial Expired</h1> 
                      <p id="success-text">Upgrade your ReadyShop plan to re-activate your store.</p>
                  </div>
              </Col>
            </Row>
          </Container>
        </>
        :
        <>
          <Navbar bg="dark" variant="dark" className="mb-4">
              <Container>
                  <Navbar.Brand href="/" className="mx-auto">
                    {storeName}
                  </Navbar.Brand>
                  <Navbar.Collapse className="justify-content-end">
                    <Nav>
                      <NavDropdown title="Cart" className="dropdown-menu-cart" onClick={() => setRerenderCart(!rerenderCart)}>
                        <div className="p-3" style={{minWidth: "250px"}}>
                          {
                            showCart()
                          }
                          
                        </div>
                      </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
              </Container>
          </Navbar>
          <Container className="content mb-3">
              <Outlet context={{
                storeName, setStoreName,
                plan, setPlan,
                trialExpiry, setTrialExpiry
              }} />
          </Container>
          <footer class="text-center">
            <div class="text-center p-3" style={{"fontColor": "#575757"}}>
              Powered By{' '}
              <strong>
              <a href="https://readyshop.io" style={{"textDecoration":"none"}}>ReadyShop</a>
              </strong>
            </div>
          </footer>
        </>
      }
    </>
  )
};

export default Layout;